<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <i class="key mr-2"></i> Change Password
          </p>
        </header>
        <section class="modal-card-body">
          <div v-if="form.visible">
            <form autocomplete="off">
              <b-field
                :type="form.pass.status"
                :message="form.pass.message"
                label="New Password"
              >
                <b-input
                  type="password"
                  v-model="form.pass.data"
                  placeholder="Password"
                  required
                  autocomplete="off"
                  password-reveal
                ></b-input>
              </b-field>
            </form>
          </div>
          <div class="content response mt-3">
            <b-message :type="form.status" v-if="form.result">
              {{ form.message }}
            </b-message>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="close()"> Cancel </b-button>
          <b-button
            v-if="form.visible"
            type="is-primary"
            @click="saveItem()"
            icon-left="floppy-disk"
          >
            Save Password
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";
import toast from "@/mixins/toast";

export default {
  name: "EditStaffPassModal",
  props: {
    email: String,
  },
  mixins: [formCheck, toast],
  data() {
    return {
      token: null,
      form: {
        pass: {
          data: "",
          status: null,
          message: null,
        },
        visible: true,
        message: null,
        result: false,
        status: null,
      },
    };
  },
  methods: {
    saveItem() {
      if (this.formCheck("pass")) {
        this.axios
          .post(`${this.$env.api}/staff/manage_staff.php`, {
            pass: this.form.pass.data,
            itemID: this.$store.state.editingStaff, //ededed,
            token: localStorage.sxAdminToken,
            action: "updatePass",
          })
          .then((response) => {
            //this.isLoading = false;
            this.form.result = true;
            console.log(response.data);
            if (response.data.success) {
              this.form.message = "Member Updated!";
              this.form.status = "is-success";
              this.form.visible = false;
              this.toast(response.data.message, "is-success");
              this.close();
            } else {
              this.form.message = "Could not update password or the password is the same as the old one.";
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    close() {
      this.resetForm();
      this.$store.commit("hideModal", "editStaffPass");
    },
    resetForm() {
      this.form.visible = true;
      this.form.status = "is-success";
      this.form.result = false;
      this.form.message = "";
      this.form.pass.data = "";
      this.form.pass.status = "";
      this.form.pass.message = "";
    },
  },
  mounted() {
    //console.log("edit staff modal mounted");
    //this.loadData();
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.editStaffPass;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "editStaffPass");
        return false;
      },
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
/* .response {
  color: #181818 !important;
} */
</style>