<template>
  <b-navbar
    class="shadow"
    wrapper-class="container"
    :mobile-burger="false"
    :fixed-top="true"
    type="is-sxgray5"
  >
    <template #brand v-if="!sidebarShow">
      <b-navbar-item
        class="is-sxlight3"
        @click="toggleSidebar()"
      >
        <img src="@/assets/sx_logo_admin_light.svg" alt="" width="100px">
      </b-navbar-item>
    </template>
    <!-- <template #start>
      <b-navbar-item @click="logout()"> Logout </b-navbar-item>
    </template> -->

    <template #end>
      <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
        Dashboard
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/adverts' }">
        Adverts
      </b-navbar-item>
      <b-navbar-item @click="logout()"> Logout </b-navbar-item>
    </template>
  </b-navbar>
</template>

<style scoped>
.shadow {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.17);
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {};
  },
  computed: {
    ...mapState(["sidebarShow"]),
  },
  methods: {
    logout() {
      this.$store.commit("logoutUser");
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
    },
    //sidebar
    toggleSidebar() {
      this.btSidebar = !this.btSidebar;
      this.$store.commit("toggleSidebar");
    },
  },
};
</script>
