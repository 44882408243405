import { render, staticRenderFns } from "./AddStaffModal.vue?vue&type=template&id=ef5dc19e&scoped=true&"
import script from "./AddStaffModal.vue?vue&type=script&lang=js&"
export * from "./AddStaffModal.vue?vue&type=script&lang=js&"
import style0 from "./AddStaffModal.vue?vue&type=style&index=0&id=ef5dc19e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef5dc19e",
  null
  
)

export default component.exports