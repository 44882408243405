<template>

    <b-menu :activable="false" class="is-custom-mobile">
      <!-- <b-menu-list label="Logs">
        <b-menu-item
          tag="router-link"
          :to="{ path: '/proofinglogs' }"
          icon="clock-rotate-left"
          label="Proofing Logs"
          :active="isActive.proofinglogs"
        ></b-menu-item>
        <b-menu-item
          tag="router-link"
          :to="{ path: '/viewinglogs' }"
          icon="eye"
          label="Viewing Logs"
          :active="isActive.viewinglogs"
        ></b-menu-item>
      </b-menu-list> -->
      <b-menu-list label="Manage Adverts">
        <b-menu-item
          icon="files"
          label="Adverts"
          @click="goTo('adverts')"
          :active="isActive == 'adverts'"
        ></b-menu-item>
        <b-menu-item
          icon="folder-grid"
          label="Archive"
          @click="goTo('adverts-archive')"
          :active="isActive == 'adverts-archive'"
        ></b-menu-item>

      </b-menu-list>
      <b-menu-list label="Manage Reports">
        <b-menu-item
          icon="triangle-exclamation"
          label="Reports"
          @click="goTo('reports')"
          :active="isActive == 'reports'"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list label="Manage Banners">
        <b-menu-item
          icon="file-image"
          label="Banners"
          @click="goTo('banners')"
          :active="isActive == 'banners'"
        ></b-menu-item>
        <b-menu-item
          icon="folder-grid"
          label="Archive"
          @click="goTo('banners-archive')"
          :active="isActive == 'banners-archive'"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list label="Users">
        <b-menu-item
          icon="user"
          label="Users"
          @click="goTo('users')"
          :active="isActive == 'users'"
        >
        <b-menu-item
            @click="showModal('addUser')"
            icon="user-plus"
            label="Add User"
          ></b-menu-item>
      </b-menu-item>
      </b-menu-list>
      
      <b-menu-list label="Staff">
        <b-menu-item
          icon="user-bounty-hunter"
          label="Staff"
          @click="goTo('staff')"
          :active="isActive == 'staff'"
        >
          <b-menu-item
            @click="showModal('addStaff')"
            icon="user-plus"
            label="Add Member"
          ></b-menu-item>
        </b-menu-item>
      </b-menu-list>
      <b-menu-list label="Catalog">
        <b-menu-item
          icon="list-ul"
          label="Categories"
          @click="goTo('categories')"
          :active="isActive == 'categories'"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list label="Pages">
        <b-menu-item
          icon="files"
          label="Pages"
          @click="goTo('pages')"
          :active="isActive == 'pages'"
        ></b-menu-item>
        <b-menu-item
          icon="files"
          label="Pages Menu"
          @click="goTo('pages-menu')"
          :active="isActive == 'pages-menu'"
        ></b-menu-item>
        <b-menu-item
          icon="images"
          label="Media"
          @click="goTo('media')"
          :active="isActive == 'media'"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list label="General">
        <b-menu-item
          icon="house-chimney"
          label="Dashboard"
          :active="isActive == 'dashboard'"
          @click="goTo('dashboard')"
        ></b-menu-item>
        <!-- <b-menu-item
          tag="router-link"
          :to="{ path: '/test' }"
          icon="vial"
          label="Test Page"
          :active="isActive.test"
        ></b-menu-item> -->
        <b-menu-item
          icon="arrow-right-from-bracket"
          label="Logout"
          @click="logout()"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
 
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserMenu",
  props: {
    activeElement: String,
  },
  data() {
    return {
      isActive: "dashboard"
    };
  },
  methods: {
    showModal(modal) {
      this.$store.commit("showModal", modal);
    },
    logout() {
      this.$store.commit("logoutUser");
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
    },
    setActive(element) {
      this.isActive[element] = true;
    },
    goTo(path) {
      let curentPath = this.$route.path;
      curentPath = curentPath.replace("/", "");
      if (curentPath == path) {
        return;
      }
      this.$router.push({ path: "/"+path }).catch((err) => {
        console.log(err);
      });
      this.isActive = path;
    },
  },
  mounted() {
    //this.isActive[this.activeElement] = true;
    //console.log(this.isActive[this.activeElement]);
    //console.log(this.activeElement);
    let path = this.$route.path;
    path = path.replace("/", "");
    this.isActive = path;
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
<style scoped></style>
