<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <Navbar v-if="['LoginView'].indexOf($route.name) == -1" />
    <Sidebar v-if="['LoginView'].indexOf($route.name) == -1" />
    <div class="sb-button" :class="sidebarShow ? 'sidebar-open': 'sidebar-closed' " v-if="['LoginView'].indexOf($route.name) == -1">
      <b-button
        type="is-primary"
        :icon-right="sidebarShow ? 'chevron-left': 'chevron-right'"
        @click="toggleSidebar()"
      ></b-button>
    </div>
    <div class="main container" v-if="['LoginView'].indexOf($route.name) == -1" >
      <div class="columns">
        <!-- <div class="column is-2">
          <UserMenu activeElement="dashboard" />
        </div> -->
        <div class="column">
          <router-view class="site-content" />
        </div>
      </div>
    </div>
    <div v-else>
      <router-view class="site-content" />
    </div>

    <Footer v-if="['LoginView'].indexOf($route.name) == -1" />
    <!-- <AddStaffModal />
    <EditStaffModal />
    <EditStaffPassModal /> -->

  </div>
</template>


<script>
import { mapState } from "vuex";

import Navbar from "@/components/navigation/Navbar.vue";
import Sidebar from "@/components/navigation/Sidebar.vue";
import Footer from "@/components/navigation/Footer.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";


import AddStaffModal from "@/components/staff/AddStaffModal.vue";
import EditStaffModal from "@/components/staff/EditStaffModal.vue";
import EditStaffPassModal from "@/components/staff/EditStaffPassModal.vue";

export default {
  name: "app",
  components: {
    Navbar,
    Sidebar,
    Footer,
    UserMenu,
    AddStaffModal,
    EditStaffModal,
    EditStaffPassModal,
  },
  data() {
    return {
      polling: null,
      btSidebar: false,
    };
  },
  mounted() {
    console.log("APP MOUNTED!");
    //!! this.$store.dispatch("loadCategories");
    console.log("Begin User Check!");
    if (!this.$store.state.user.isLogged) {
      this.checkLoged();
      console.log(this.$store.state.user.isLogged);
      //console.log(this.$route.name);
    }
  },
  computed: {
    ...mapState(["sidebarShow"]),
  },
  methods: {
    checkLoged: function () {
      if (localStorage.sxAdminToken) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.sxAdminToken);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/validate.php`, {
            token: localStorage.sxAdminToken,
          })
          .then((response) => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("validateUser", response.data.data);
              this.$store.dispatch("loadCategories");
              console.log("Loged!");
              this.$router.push({ path: "/dashboard" }).catch((err) => {
                console.log(err);
              });
              console.log(response.data.data);
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
    //pull data from server
    pollData() {
      this.polling = setInterval(() => {
        if (this.$store.state.user.isLogged) {
          console.log("Polling...");
          this.countInbox();
        } else {
          console.log("Not Polling...");
        }
        // if (this.errorCout > 10) {
        //   clearInterval(this.polling);
        // } else {
        //   this.checkNewOrders();
        // }
      }, 10000);
    },
    countInbox: function () {
      this.axios
        .post(`${this.$env.api}/projects/manage_projects.php`, {
          token: localStorage.sxToken,
          action: "countSigned",
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.success) {
            //console.log("Proofs:" + response.data.counted);
            this.$store.commit("setCount", response.data.counted);
          } else {
            console.log("Error getting data");
          }
        })
        .catch((error) => {
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    //sidebar
    toggleSidebar() {
      this.btSidebar = !this.btSidebar;
      this.$store.commit("toggleSidebar");
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    //this.countInbox();
   // this.pollData();
  },
};
</script>

<style lang="scss">
//Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");

// Import Fontawesome
//@import "static/fontawesome/css/all.min.css";
@import "static/syntho/style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #97a3ae;
$success: #7cbc4b;
// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #f4f4f4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
// $body-background-color: #4a4f54;
$content-heading-color: #000000;

$sxportable: #ffcd0b;
$sxmodular: #f37422;
$sxcustom: #ed3a25;
$sxgraphic: #71cee3;
$sxproject: #b393b0;

$sxgreen: #7cbc4b;
$sxgreen-light: #8dc457;
$sxgreen-dark: #669f42;
$sxgreen-dark2: #5f9340;

$sxgray: #4a4f54;
$sxgray2: #41464c;
$sxgray3: #383c41;
$sxgray4: #2e3136;
$sxgray5: #23262b;

$sxlight: #97a3ae;
$sxlight2: #d8dfe1;
$sxlight3: #f7f7f7;

$sxmain: #7cbc4b;
$pgmain: #a0218c;

// $navbar-item-color: $sxgray!important;
// $navbar-dropdown-arrow: $sxgray!important;

$footer-background-color: $sxgray5;
$footer-color: $sxlight2;
$footer-padding: 2rem 1.5rem 2rem;

//$family-primary: 'Roboto', sans-serif;
$family-primary: "Roboto", sans-serif;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
  "sxportable": (
    $sxportable,
    $white,
  ),
  "sxmodular": (
    $sxmodular,
    $white,
  ),
  "sxcustom": (
    $sxcustom,
    $white,
  ),
  "sxgraphic": (
    $sxgraphic,
    $white,
  ),
  "sxproject": (
    $sxproject,
    $white,
  ),
  "sxgreen": (
    $sxgreen,
    $white,
  ),
  "sxgreen-light": (
    $sxgreen-light,
    $white,
  ),
  "sxgreen-dark": (
    $sxgreen-dark,
    $white,
  ),
  "sxgreen-dark2": (
    $sxgreen-dark2,
    $white,
  ),
  "sxgray": (
    $sxgray,
    $white,
  ),
  "sxgray2": (
    $sxgray2,
    $white,
  ),
  "sxgray3": (
    $sxgray3,
    $white,
  ),
  "sxgray4": (
    $sxgray4,
    $white,
  ),
  "sxgray5": (
    $sxgray5,
    $white,
  ),
  "sxlight": (
    $sxlight,
    $white,
  ),
  "sxlight2": (
    $sxlight2,
    $sxgray,
  ),
  "sxmain": (
    $sxmain,
    $white,
  ),
  "pgmain": (
    $pgmain,
    $white,
  ),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-icon-color: $sxlight;
$input-icon-active-color: $sxgray;

$menu-item-color: $mylight;

.sidebar-button {
  position: fixed;
  top: 25px;
  right: 16px;
  z-index: 40;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 1023px) {
  .sidebar-button {
    display: unset;
  }
}

.navbar-item {
  color: #b3bcc5 !important;
  font-weight: 400 !important;
  // text-transform: uppercase;
  font-size: 14px;
}

// .menu {
//   font-family: "Roboto", sans-serif;
//   text-transform: uppercase;
//   font-size: 0.9em !important;
// }

//nav icon
#nav-icon3 {
  width: 25px;
  height: 30px;
  position: relative;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #7cbc4b;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 7px;
}

#nav-icon3 span:nth-child(4) {
  top: 14px;
}

#nav-icon3.open span:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 7px;
  width: 0%;
  left: 50%;
}

.header-title {
  color: #fff;
  font-size: 4rem;
  font-family: "Bebas Neue";
}
.header-subtitle {
  color: #fff;
  font-size: 2rem;
}
//end nav icon

$card-header-background-color: $primary;

.card-header-image {
  background-color: #0f92ff !important;
}

.card-header-stats {
  background-color: #0f92ff !important;
}

// .card-header-actions{
//   background-color: #0f92ff!important;
// }

$card-header-color: $sxlight3;

$card-header-pasxing: 30px 20px;

// .card-header-title {
//   font-size: 2.3rem;
//   text-transform: uppercase;
//   font-weight: 100 !important;
// }

.card-footer {
  padding: 20px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.card-footer-sample {
  padding: 0px !important;
  flex-shrink: unset;
  justify-content: unset;
}

.card-footer .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-title {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 600 !important;
}
// .content li + li {
//      margin-top: 0em!important;
// }

// .content ul {
//     margin-left: 0em!important;
//     margin-top: 0em!important;
// }

.main {
  padding-top: 40px;
  min-height: calc(100vh - 128px);
}

.loginbg {
  background: #23262b;
}

.link-bt {
  cursor: pointer;
}
.link-red {
  color: #ed3a25;
}
.link-green {
  color: #7cbc4b;
}

.icon-single {
  color: #3e8ed0;
}
.icon-group {
  color: #f14668;
}

.status-ico{
  float: left;
  font-size: x-large;
  opacity: 0.5;
}

.status-ico:hover{
  opacity: 1;
}

.ico-bt {
  all: unset;
  cursor: pointer;
  font-size: 20px;
}

.ico-red {
  color: #f14668;
}
.ico-green {
  color: #7cbc4b;
}
.ico-blue {
  color: #3e8ed0;
}
.ico-orange {
  color: #ffa600;
}
.ico-gray {
  color: #4a4f54;
}

.gree-forever {
  color: #7cbc4b!important;
}

.red-bt,
.green-bt,
.orange-bt,
.blue-bt {
  color: #c9c9c9;
  cursor: pointer;
  float: left;
}
.red-bt:hover {
  color: #f14668;
}
.green-bt:hover {
  color: #7cbc4b;
}
.blue-bt:hover {
  color: #3e8ed0;
}
.orange-bt:hover {
  color: #ffa600;
}

.green-bt-active {
  color: #7cbc4b;
}

.table {
  background-color: transparent !important;
}

.has-title {
  width: 100%;
  overflow: hisxen;
}

.vdr-stick-tm,
.vdr-stick-bm,
.vdr-stick-ml,
.vdr-stick-mr {
  display: none !important;
}

.icon {
  //margin: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.grid:before {
  content: "" !important;
}

.image:before {
  content: "" !important;
}

.icon.is-large {
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
}

.not-clickable {
  pointer-events: none;
}
.not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.dashboard-box {
  color: #7a7a7a;
  cursor: pointer;
}

.dashboard-box:hover .el-color {
  color: #fff;
}

.t-num {
  font-weight: 700;
  font-size: 1.5rem;
}

.t-total {
  font-size: 1rem;
}

.sx-num {
  font-weight: 700;
  font-size: 2rem;
  color: #7cbc4b;
}

.pg-num {
  font-weight: 700;
  font-size: 2rem;
  color: #a0218c;
}

.box-bt {
  text-align: center;
  color: #4a4f54 !important;
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
}

.box-bt:hover {
  color: #ffffff !important;
  background-color: #7cbc4b;
}

.box-green .el-color {
  color: #7cbc4b;
}

.box-green:hover {
  background-color: #7cbc4b;
  color: #fff;
}

.box-red .el-color {
  color: #f14668;
}

.box-red:hover {
  background-color: #f14668;
  color: #fff;
}

.box-orange .el-color {
  color: #ffa600;
}

.box-orange:hover {
  background-color: #ffa600;
  color: #fff;
}

.box-blue .el-color {
  color: #3e8ed0;
}

.box-blue:hover {
  background-color: #3e8ed0;
  color: #fff;
}

.box-gray .el-color {
  color: #4a4f54;
}

.box-gray:hover {
  background-color: #4a4f54;
  color: #fff;
}

.card-header {
  align-items: center;
  background-color: #4a4f54 !important;
}

.card-header-green {
  background-color: #7cbc4b !important;
}

.card-header-blue {
  background-color: #3e8ed0 !important;
}

.card-header-red {
  background-color: #f14668 !important;
}

.card-header-gray {
  background-color: #4a4f54 !important;
}

.card-header-orange {
  background-color: #ffa600 !important;
}

.menu-list a {
  font-family: "Roboto", sans-serif;
  // text-transform: uppercase;
  font-size: .9em !important;
}

.status-blue select{
  color: #3e8ed0 !important;
  border-color: #3e8ed0 !important;
}

.status-green select{
  color: #7cbc4b !important;
  border-color: #7cbc4b !important;
}

.status-red select{
  color: #f14668 !important;
  border-color: #f14668 !important;
}

.status-orange select{
  color: #ffa600 !important;
  border-color: #ffa600 !important;
}

.status-blue .select:not(.is-multiple):not(.is-loading):after {
    border-color: #3e8ed0;
}

.status-green .select:not(.is-multiple):not(.is-loading):after {
    border-color: #7cbc4b;
}

.status-red .select:not(.is-multiple):not(.is-loading):after {
    border-color: #f14668;
}

.status-orange .select:not(.is-multiple):not(.is-loading):after {
    border-color: #ffa600;
}

#app{
  background-color: #f5f5f5;
}

//fixes
.tag::before{
  content: none !important;
}

//sidebar=====================================
.sb-button {
  position: fixed;
  // top: calc(50% - 20px);
  top: 6px;
  left: 20px;
  z-index: 40;
  transition: all 0.3s ease-in-out;
}
.sb-button.sidebar-open {
  left: 210px;
}

//sidebar=====================================
// .p-1 {
//   padding: 1em;
// }
// .sidebar-page {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     min-height: 100%;
//     // min-height: 100vh;
//     .sidebar-layout {
//         display: flex;
//         flex-direction: row;
//         min-height: 100%;
//         // min-height: 100vh;
//     }
// }
// @media screen and (max-width: 1023px) {
//     .b-sidebar {
//         .sidebar-content {
//             &.is-mini-mobile {
//                 &:not(.is-mini-expand),
//                 &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
//                     .menu-list {
//                         li {
//                             a {
//                                 span:nth-child(2) {
//                                     display: none;
//                                 }
//                             }
//                             ul {
//                                 padding-left: 0;
//                                 li {
//                                     a {
//                                         display: inline-block;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .menu-label:not(:last-child) {
//                         margin-bottom: 0;
//                         display: none;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media screen and (min-width: 1024px) {
//     .b-sidebar {
//         .sidebar-content {
//             &.is-mini {
//                 &:not(.is-mini-expand),
//                 &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
//                     .menu-list {
//                         li {
//                             a {
//                                 span:nth-child(2) {
//                                     display: none;
//                                 }
//                             }
//                             ul {
//                                 padding-left: 0;
//                                 li {
//                                     a {
//                                         display: inline-block;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .menu-label:not(:last-child) {
//                         margin-bottom: 0;
//                         display: none;
//                     }
//                 }
//             }
//         }
//     }
// }
// .is-mini-expand {
//     .menu-list a {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }
// }

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
