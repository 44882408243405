<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <i class="user-bounty-hunter mr-2"></i> Edit Staff Member
          </p>
        </header>
        <section class="modal-card-body">
          <div v-if="form.visible">

            <b-field :type="form.type.status" :message="form.type.message" label="Type">
              <b-select  v-model="form.type.data" placeholder="Select a type" expanded>
                <option value="moderator">Moderator</option>
                <option value="admin">Admin</option>
              </b-select>
            </b-field>

           <b-field :type="form.name.status" :message="form.name.message" label="Name">
              <b-input
                v-model="form.name.data"
                placeholder="Name"
                required
              ></b-input>
            </b-field>

            <b-field :type="form.email.status" :message="form.email.message"  label="Email">
              <b-input
                v-model="form.email.data"
                placeholder="Email"
                required
              ></b-input>
            </b-field>

          </div>
          <div class="content response mt-3">
            <b-message :type="form.status" v-if="form.result">
              {{ form.message }}
            </b-message>
          </div>
         
        </section>
        <footer class="modal-card-foot">
          <b-button @click="close()"> Close </b-button>
          <b-button
            v-if="form.visible"
            type="is-primary"
            @click="saveItem()"
            icon-left="floppy-disk"
          >
            Save Member
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "EditStaffModal",
  props: {
    email: String,
  },
  mixins: [formCheck],
  data() {
    return {
      token: null,
      latestCreatedID: null,
      itemData: {
        name: "",
        email: "",
        type: "",
      },
      form: {
        type: {
          data: "",
          status: null,
          message: null,
        },
        name: {
          data: "",
          status: null,
          message: null,
        },
        email: {
          data: "",
          status: null,
          message: null,
        },
        visible: true,
        message: null,
        result: false,
        status: null,
      },
    };
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/staff/manage_staff.php`, {
          token: localStorage.sxAdminToken,
          itemID: this.$store.state.editingStaff, //ededed,
          action: "getItem",
        })
        .then((response) => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.itemData = response.data.item;
            this.setFormData();
            this.isEmpty = false;
          } else {
            console.log("Error getting data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //.finally(() => (this.loading = false));
    },
    saveItem() {
      if (this.formCheck("name") & this.emailCheck("email") & this.formCheck("type")) {
        this.axios
          .post(`${this.$env.api}/staff/manage_staff.php`, {
            name: this.form.name.data,
            email: this.form.email.data,
            type: this.form.type.data,
            //pass: this.form.pass.data,
            itemID: this.$store.state.editingStaff, //ededed,
            token: localStorage.sxAdminToken,
            action: "updateItem",
          })
          .then((response) => {
            //this.isLoading = false;
            this.form.result = true;
            console.log(response.data);
            if (response.data.success) {
              this.form.message = "Member Updated!";
              this.form.status = "is-success";
              this.form.visible = false;
              this.$root.$emit("didUpdateStaff");
              this.close();
            } else {
              this.form.message = response.data.message;
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    close() {
      this.resetForm();
      this.$store.commit("hideModal", "editStaff");
    },
    resetForm() {
      this.form.visible = true;
      this.form.status = "is-success";
      this.form.result = false;
      this.form.message = "";

      this.form.type.data = "";
      this.form.type.status = "";
      this.form.type.message = "";

      this.form.name.data = "";
      this.form.name.status = "";
      this.form.name.message = "";

      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
    },
    setFormData() {
      console.log("setFormData: ",this.itemData);
      this.form.name.data = this.itemData.name;
      this.form.email.data = this.itemData.email;
      this.form.type.data = this.itemData.type;
    },
    // editStaff() {
    //   this.resetForm();
    //   if(this.latestCreatedID) {
    //     // this.$router.push({
    //     //   name: "editStaff",
    //     //   params: {
    //     //     id: this.latestCreatedID,
    //     //   },
    //     // });
    //     this.$store.commit("setEditStaff", this.latestCreatedID);
    //     this.latestCreatedID = null;
    //     this.$router.push("/edit-staff");
    //     this.$store.commit("hideModal", "editStaff");
    //   }
    // },
  },
  mounted() {
    //console.log("edit staff modal mounted");
    //this.loadData();
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.editStaff;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "editStaff");
        return false;
      },
    },
  },
  watch: {
    isActive: function (val) {
      if (val) {
        this.loadData();
      }
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
/* .response {
  color: #181818 !important;
} */
</style>
