export default {
  methods: {
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    emailCheck() {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form.email.data === "" || !re.test(this.form.email.data)) {
        this.form.email.status = "is-danger";
        this.form.email.message = "Please enter a valid email address";
        return false;
      } else {
        this.form.email.status = "is-success";
        this.form.email.message = null;
        return true;
      }
    },
    generalEmailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (e === "" || !re.test(e)) {
        return false;
      } else {
        return true;
      }
    },
  },
};


// export default {
//   methods: {
//     formCheck(e) {
//       if (!this.form[e].data) {
//         this.form[e].status = "is-danger";
//         this.form[e].message = "Please fill out all required fields";
//         return false;
//       } else {
//         this.form[e].status = "is-success";
//         return true;
//       }
//     },
//     emailCheck(e) {
//       var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//       if (!this.form[e].data || !re.test(String(e).toLowerCase())) {
//         this.form[e].status = "is-danger";
//         this.form[e].message = "Please enter a valid email address";
//         return false;
//       } else {
//         this.form[e].status = "is-success";
//         return true;
//       }
//     },
//   },
// };
