import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import LoginView from "../views/LoginView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "DashboardView",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/adverts/:page?",
    name: "AdvertsView",
    component: () =>
      import(/* webpackChunkName: "user-adverts" */ "../views/AdvertsView.vue"),
  },
  {
    path: "/adverts-archive/:page?",
    name: "AdvertsArchiveView",
    component: () =>
      import(/* webpackChunkName: "adverts-archive" */ "../views/AdvertsArchiveView.vue"),
  },
  {
    path: "/reports/:page?",
    name: "ReportsView",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/ReportsView.vue"),
  },
  // {
  //   path: "/adv-archive/:page?",
  //   name: "AdvArchiveView",
  //   component: () =>
  //     import(/* webpackChunkName: "advarchive" */ "../views/AdvArchiveView.vue"),
  // },
  {
    path: "/banners/:page?",
    name: "BannersView",
    component: () =>
      import(/* webpackChunkName: "banners" */ "../views/BannersView.vue"),
  },
  {
    path: "/banners-archive/:page?",
    name: "BannersArchiveView",
    component: () =>
      import(/* webpackChunkName: "bannersarchive" */ "../views/BannersArchiveView.vue"),
  },
  {
    path: "/users/:page?",
    name: "UsersView",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersView.vue"),
  },
  {
    path: "/staff/:page?",
    name: "StaffView",
    component: () =>
      import(/* webpackChunkName: "staff" */ "../views/StaffView.vue"),
  },
  {
    path: "/categories",
    name: "CategoriesView",
    component: () =>
      import(/* webpackChunkName: "categories" */ "../views/CategoriesView.vue"),
  },
  {
    path: "/pages/:page?",
    name: "PagesView",
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/PagesView.vue"),
  },
  {
    path: "/pages-menu/",
    name: "PagesMenuView",
    component: () =>
      import(/* webpackChunkName: "pagesmenu" */ "../views/PagesMenuView.vue"),
  },
  {
    path: "/media/",
    name: "MediaView",
    component: () =>
      import(/* webpackChunkName: "media" */ "../views/MediaView.vue"),
  },
  // {
  //   path: "/adverts/:page?",
  //   name: "ManageAdvertsView",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "manage-adverts" */
  //       "../views/ManageAdvertsView.vue"
  //     ),
  // },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

const router = new VueRouter({
  mode: "history",
  base: "/",
  //base: "/admin/",
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name !== "LoginView" && !store.state.user.isLogged) {
    next({
      path: "/",
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
