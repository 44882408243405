<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <i class="user-bounty-hunter mr-2"></i> Add Staff Member
          </p>
        </header>
        <section class="modal-card-body">
          <div v-if="form.visible">
          <form autocomplete="off">
            
            <b-field :type="form.type.status" :message="form.type.message" label="Type">
              <b-select  v-model="form.type.data" placeholder="Select a type" expanded>
                <option value="moderator">Moderator</option>
                <option value="admin">Admin</option>
              </b-select>
            </b-field>

           <b-field label="Name" :type="form.name.status" :message="form.name.message">
              <b-input
                v-model="form.name.data"
                placeholder="Name"
                required
              ></b-input>
            </b-field>

            <b-field label="Email" :type="form.email.status" :message="form.email.message">
              <b-input
                v-model="form.email.data"
                placeholder="Email"
                required
              ></b-input>
            </b-field>

            <b-field label="Password" :type="form.pass.status" :message="form.pass.message">
              <b-input
                type="password"
                v-model="form.pass.data"
                placeholder="Password"
                required
                password-reveal
              ></b-input>
            </b-field>
            </form>
          </div>
          <div class="content response mt-3">
            <b-message :type="form.status" v-if="form.result">
              {{ form.message }}
            </b-message>
          </div>
         
        </section>
        <footer class="modal-card-foot">
          <b-button @click="close()"> Close </b-button>
          <b-button
            v-if="form.visible"
            type="is-primary"
            @click="addItem()"
            icon-left="user-plus"
          >
            Add Member
          </b-button>
          <b-button v-if="!form.visible" type="is-info" @click="resetForm()" icon-left="user-plus">
            Add Another
          </b-button>
          <!-- <b-button v-if="!form.visible" type="is-primary" @click="editProof()" icon-left="pen-to-square">
            Edit
          </b-button> -->
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "AddStaffModal",
  props: {
    email: String,
  },
  mixins: [formCheck],
  data() {
    return {
      token: null,
      latestCreatedID: null,
      form: {
        name: {
          data: "",
          status: null,
          message: null,
        },
        email: {
          data: "",
          status: null,
          message: null,
        },
        pass: {
          data: "",
          status: null,
          message: null,
        },
        type: {
          data: "",
          status: null,
          message: null,
        },
        visible: true,
        message: null,
        result: false,
        status: null,
      },
    };
  },
  methods: {
    randomPassword() {
      var chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
      var pass = "";
      for (var x = 0; x < 10; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      return pass;
    },
    addItem() {
      if (this.formCheck("name") & this.emailCheck("email") & this.formCheck("pass")) {
        this.axios
          .post(`${this.$env.api}/staff/manage_staff.php`, {
            name: this.form.name.data,
            email: this.form.email.data,
            pass: this.form.pass.data,
            type: this.form.type.data,
            token: localStorage.sxAdminToken,
            action: "addItem",
          })
          .then((response) => {
            //this.isLoading = false;
            this.form.result = true;
            console.log(response.data);
            if (response.data.success) {
              this.form.message = "New Member Added!";
              this.form.status = "is-success";
              this.form.visible = false;
              this.latestCreatedID = response.data.lastID;
              this.$root.$emit("didAddStaff");
            } else {
              this.form.message = response.data.message;
              this.form.status = "is-danger";
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    close() {
      this.$store.commit("hideModal", "addStaff");
    },
    resetForm() {
      this.form.visible = true;
      this.form.status = "is-success";
      this.form.result = false;
      this.form.message = "";

      this.form.type.data = "";
      this.form.type.status = "";
      this.form.type.message = "";

      this.form.name.data = "";
      this.form.name.status = "";
      this.form.name.message = "";

      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";

      this.form.pass.data = this.randomPassword();
      this.form.pass.status = "";
      this.form.pass.message = "";
    },
  },
  mounted() {
    //console.log("add staff modal mounted");
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.addStaff;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "addStaff");
        return false;
      },
    },
  },  
  watch: {
    isActive: function (val) {
      if (val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
/* .response {
  color: #181818 !important;
} */
</style>
