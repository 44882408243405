<template>
      <div class="sidebar-page">
        <section class="sidebar-layout">
    <b-sidebar
      :delay="expandWithDelay ? 500 : null"
      v-model="sidebarShow"
      :can-cancel="false"
      :fullheight="true"
      type="is-mydark"
      mobile="reduce"
      :expand-on-hover="true"
      :reduce="reduce"
    >
      <div class="sidebar-blocks">
        <div class="top-block">
          <div class="block">
            <img src="@/assets/sx_logo_admin_light.svg" alt="" width="150px">
          </div>

          <div class="block">
            <UserMenu />
          </div>
        </div>

        <!-- <div class="bottom-block mb-4">
          <div v-if="user.isLogged">
            <div v-if="this.proofInfo.signed > 1" class="has-text-centered">
                  <b-tag :type="showTag(this.proofInfo.signed).state" size="is-large">{{
                    showTag(this.proofInfo.signed).text
                  }}</b-tag>
            </div>
            <div v-if="this.proofInfo.signed < 2">
              <b-button
                type="is-success mb-2"
                icon-left="circle-check"
                @click="approveProof()"
                expanded
              >
                Approve
              </b-button>
              <b-button
                type="is-danger"
                icon-left="ban"
                @click="rejectProof()"
                expanded
              >
                Decline
              </b-button>
    
            </div>
          </div>
        </div> -->
      </div>
    </b-sidebar>
  </section></div>
</template>

<script>
import { mapState } from "vuex";
import toast from "@/mixins/toast";
import UserMenu from "@/components/navigation/UserMenu.vue";
export default {
  name: "Sidebar",
  components: {
    UserMenu,
  },
  mixins: [toast],
  data() {
    return {
      isOpen: true,
      isLoading: false,
      token: null,
      expandOnHover: false,
      expandWithDelay: false,
      mobile: "hidden",
      reduce: false,
      //
      isType: 0,
    };
  },
  methods: {
    togleSidebar() {
      this.isOpen = !this.isOpen;
    },
    approveProof() {
      this.$emit("approve");
    },
    rejectProof() {
      this.$emit("reject");
    },
    login() {
      this.$emit("login");
    },
    formatDate() {
      if (this.proofInfo.date) {
        const date = new Date(Number(this.proofInfo.date));
        return date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      } else {
        return "";
      }
    },
    showTag(value) {
      if (!value) return { state: "is-light", text: "No Status" };
      if (value == "0") {
        return { state: "is-info", text: "New" };
      }else if (value == "1"){
        return { state: "is-info", text: "Awaiting" };
      } else if (value == "2") {
        return { state: "is-success", text: "Approved" };
      } else if (value == "3") {
        return { state: "is-danger", text: "Rejected" };
      } else if (value == "4") {
        return { state: "is-light", text: "Archived" };
      } else {
        return { state: "is-light", text: "No Status" };
      }
    },
  },
  mounted() {
    console.log("sidebar mounted");
  },
  computed: {
    ...mapState(["user", "sidebarShow"]),
    hasVersion() {
      if(this.proofInfo.proofs[this.currentPage]){
        return this.proofInfo.proofs[this.currentPage].version;
      }else{
        return "-";
      }
    },
    hasNotes() {
      if(this.proofInfo.proofs[this.currentPage]){
        return this.proofInfo.proofs[this.currentPage].notes;
      }else{
        return "-";
      }
    },
    isActive: {
      get: function () {
        return this.$store.state.modals.approveProof;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "approveProof");
        return false;
      },
    },
  },
};
</script>
<style>
.b-sidebar .sidebar-content {
  width: 200px !important;
  padding: 15px;
}

.sidebar-blocks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* .dd-sidebar-line .sidebar-content {
  border-left: 2px solid #7cbc4b;
}
.pg-sidebar-line .sidebar-content {
  border-left: 2px solid #a0218c;
} */

.proof-descr {
  position: fixed;
  bottom: 110px;
  right: 20px;
  max-width: 400px;
  z-index: 51;
}

[disabled].button {
  cursor: default;
}


.proof-info-title {
  font-size: 0.8rem;
  color: #4a4f54;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.proof-info-text {
  font-size: 0.9rem;
  text-transform: capitalize;
}
.proof-text {
  line-height: 1;
}

.proof-footer-title-button {
  display: none;
}


</style>
